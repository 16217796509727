export const environment = {
  baseUrl: '',
  api: '/api/v2',
  env: 'production',
  production: true,
  stripePublishableKey:
    'pk_live_JM5pSvm6deso8IkHsSfP2Edy00ulAf4ryx',
  supabaseUrl: 'https://wnlmzhxbiwktciqndfdl.supabase.co',
  supabaseKey:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndubG16aHhiaXdrdGNpcW5kZmRsIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTIyMTQ0MjAsImV4cCI6MjAwNzc5MDQyMH0.SFIWSFFIXtwai0DKKviCPMed63YVbNZZMMBQ_4CWWPI',
  syncfusionLicenseKey:
    'ORg4AjUWIQA/Gnt2V1hiQlRPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXZTcURmXHhfdXJRR2U=',
};
