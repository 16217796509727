import { Component, Input } from '@angular/core';

import { FeedbackOptions } from 'src/app/utils/feedback';
import { tv } from 'tailwind-variants';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss'],
})
export class MessageBoxComponent {
  @Input() class = '';

  @Input() title = 'Info';
  @Input() message = '';

  @Input() type: 'info' | 'warning' | 'error' | 'success' = 'info';

  @Input() feedback: FeedbackOptions | null = null;

  private variants = tv({
    slots: {
      base: 'mb-8 rounded-3xl p-4',
      icon: 'h-5 w-5',
      title: 'text-sm font-medium',
      message: 'mt-2 text-sm',
    },
    variants: {
      type: {
        info: {
          base: 'bg-blue-500 bg-opacity-5',
          icon: 'text-blue-400',
          title: 'text-blue-600',
          message: 'text-blue-600',
        },
        warning: {
          base: 'bg-orange-500 bg-opacity-5',
          icon: 'text-orange-400',
          title: 'text-orange-600',
          message: 'text-orange-600',
        },
        error: {
          base: 'bg-red-500 bg-opacity-5',
          icon: 'text-red-400',
          title: 'text-red-600',
          message: 'text-red-600',
        },
        success: {
          base: 'bg-green-500 bg-opacity-5',
          icon: 'text-green-400',
          title: 'text-green-600',
          message: 'text-green-600',
        },
      },
    },
    compoundVariants: [],
    defaultVariants: {},
  });

  get options(): FeedbackOptions {
    return (
      this.feedback ?? {
        visible: false,
        type: this.type,
        title: this.title,
        message: this.message,
      }
    );
  }

  get baseClasses(): string {
    return this.variants({
      type: this.options.type,
    }).base({
      // overrides
      class: this.class,
    });
  }

  get iconClasses(): string {
    return this.variants({
      type: this.options.type,
    }).icon();
  }

  get titleClasses(): string {
    return this.variants({
      type: this.options.type,
    }).title();
  }

  get messageClasses(): string {
    return this.variants({
      type: this.options.type,
    }).message();
  }
}
