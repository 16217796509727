import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, filter, map } from 'rxjs';

import { AuthService } from '../../auth/auth.service';
import { UserService } from '../../auth/user.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {
  showMobileMenu = false;

  isAdminOrEditor$ = this.userService.user$.pipe(
    map(u => u?.user_type === 'admin' || u?.user_type === 'editor'),
    filter(isAdmin => isAdmin)
  );

  isScrolled$ = new BehaviorSubject<boolean>(false);

  get isLoggedIn() {
    return this.userService.user !== null;
  }

  constructor(
    public readonly userService: UserService,
    public readonly authService: AuthService,
    private readonly router: Router
  ) {}

  async signOut() {
    const { error } = await this.authService.signOut();

    if (error) {
      console.error(error);
    }

    this.router.navigate(['/sign-in']);
  }

  @HostListener('window:scroll', ['$event.target'])
  onContentScrolled(e: Document) {
    const top = e.scrollingElement?.scrollTop ?? 0;
    const isScrolled = top > 10;
    if (this.isScrolled$.value !== isScrolled) {
      this.isScrolled$.next(isScrolled);
    }
  }
}
