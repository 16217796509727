import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { adminGuard, authGuard } from './auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule),
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./auth/sign-in/sign-in.module').then(m => m.SignInModule),
  },
  {
    path: 'sign-up',
    canActivate: [],
    loadChildren: () => import('./auth/sign-up/sign-up.module').then(m => m.SignUpModule),
  },
  {
    path: 'admin',
    canActivate: [authGuard, adminGuard],
    loadChildren: () => import('./admin/admin-app.module').then(m => m.AdminAppModule),
  },
  {
    path: 'checkout',
    canActivate: [authGuard],
    loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule),
  },
  {
    path: 'account',
    canActivate: [authGuard],
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
  },
  // {
  //   path: 'fulfillment-policy',
  //   loadChildren: () =>
  //     import('./documents/fulfillment-policy/fulfillment-policy.module').then(
  //       m => m.FulfillmentPolicyModule
  //     ),
  // },
  {
    path: '',
    loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule),
  },
  // catch-all route
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
