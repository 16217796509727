<ng-container [ngSwitch]="elementType">
  <button *ngSwitchCase="'button'" [class]="classes" [disabled]="disabled">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
  <a *ngSwitchDefault [href]="href" [target]="target" [class]="classes">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
  {{ text }}
</ng-template>
