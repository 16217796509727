import { trigger, transition, style, animate, query, stagger } from '@angular/animations';
import { Component } from '@angular/core';

import { ToastAlertService } from './toast-alert.service';

const toastAlertAnimation = trigger('toastAlertAnimation', [
  transition('* <=> *', [
    query(
      ':enter',
      [style({ opacity: 0 }), stagger('60ms', animate('600ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave', animate('200ms', style({ opacity: 0 })), { optional: true }),
  ]),
]);

@Component({
  selector: 'app-toast-alert',
  templateUrl: './toast-alert.component.html',
  styleUrls: ['./toast-alert.component.scss'],
  animations: [toastAlertAnimation],
})
export class ToastAlertComponent {
  constructor(public toastAlertService: ToastAlertService) {}
}
