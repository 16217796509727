import { Injectable } from '@angular/core';

export interface ToastAlert {
  type: 'success' | 'error' | 'info';
  title: string;
  message: string;
  duration?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ToastAlertService {
  toastAlerts: ToastAlert[] = [];

  constructor() {
    // setTimeout(() => {
    //   this.showSuccess('This is a success message!');
    // }, 1000);
  }

  showSuccess(message: string, title?: string, duration?: number) {
    this.show({ type: 'success', title: title ?? 'Success!', message, duration });
  }

  show(toastAlert: ToastAlert) {
    this.toastAlerts.unshift(toastAlert);
    if (!toastAlert.duration) {
      toastAlert.duration = 5000;
    }

    setTimeout(() => {
      this.toastAlerts = this.toastAlerts.filter(t => t !== toastAlert);
    }, toastAlert.duration);
  }
}
