<div class="pointer-events-none fixed inset-y-0 right-0 z-50 max-h-screen w-full max-w-md">
  <div
    [@toastAlertAnimation]="toastAlertService.toastAlerts.length"
    class="mt-20 flex flex-col gap-4 overflow-y-auto p-4">
    <div *ngFor="let toast of toastAlertService.toastAlerts">
      <app-message-box
        [class]="'bg-opacity-20'"
        [message]="toast.message"
        [type]="toast.type"
        [title]="toast.title"></app-message-box>
    </div>
  </div>
</div>
